<template>
  <div class="login-btn-hldr">
      <Button label="Login With Ploutus Account" type="submit" @click="Login" />
  </div>
</template>

<script>
// import { useRouter } from 'vue-router';
import firebase from 'firebase';
import Button from 'primevue/button';
export default {
    components: {Button},
    setup(){
        // const router = useRouter();
        var provider = new firebase.auth.GoogleAuthProvider();
        const Login = () =>{
            firebase.auth()
            .signInWithPopup(provider)
            .then((result) => {
                /** @type {firebase.auth.OAuthCredential} */
                // var credential = result.credential;

                // This gives you a Google Access Token. You can use it to access the Google API.
                // var token = credential.accessToken;
                // The signed-in user info.
                var user = result.user;
                // ...
                // router.replace('/');
                // console.log(token)
                // console.log(user)
                // console.log(user.email)
                // console.log(user.uid)
                localStorage.setItem("ploUserName", user.displayName);
                localStorage.setItem("ploUserEmail", user.email);
                localStorage.setItem("ploUserImg", user.photoURL);
            }).catch((error) => {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...
                console.log(error)
                console.log(errorCode)
                console.log(errorMessage)
                console.log(email)
                console.log(credential)

                // router.replace('login');
            });
        }
        return{
            Login
        }
    }
    
}
</script>

<style>
.login-btn-hldr{
    margin-top: 20%;
}
</style>